exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aromatherapy-js": () => import("./../../../src/pages/aromatherapy.js" /* webpackChunkName: "component---src-pages-aromatherapy-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-eco-beauty-lifestyle-js": () => import("./../../../src/pages/eco-beauty-lifestyle.js" /* webpackChunkName: "component---src-pages-eco-beauty-lifestyle-js" */),
  "component---src-pages-eco-friendly-fashion-js": () => import("./../../../src/pages/eco-friendly-fashion.js" /* webpackChunkName: "component---src-pages-eco-friendly-fashion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-minimal-jewelry-js": () => import("./../../../src/pages/minimal-jewelry.js" /* webpackChunkName: "component---src-pages-minimal-jewelry-js" */),
  "component---src-pages-petite-brands-js": () => import("./../../../src/pages/petite-brands.js" /* webpackChunkName: "component---src-pages-petite-brands-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-secondhand-vintage-js": () => import("./../../../src/pages/secondhand-vintage.js" /* webpackChunkName: "component---src-pages-secondhand-vintage-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */),
  "slice---src-components-nav-js": () => import("./../../../src/components/nav.js" /* webpackChunkName: "slice---src-components-nav-js" */)
}

